// @import '@angular/material/prebuilt-themes/indigo-pink.css';
@use '@angular/material' as mat;

$logoPath: '../images/Synergi-Logo-rgb.png';

@import './mixins.scss';
@import '@angular/material/theming';
@include mat.core();

$my-app-primary: mat.define-palette(mat.$indigo-palette);
$my-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$my-app-theme: mat.define-light-theme($my-app-primary, $my-app-accent);

@include mat.all-component-themes($my-app-theme);
@import '~bootstrap/dist/css/bootstrap.min.css';

$fa-font-path: '~font-awesome/fonts';
@import '~font-awesome/scss/font-awesome';

// .logo {
//   width: 110px;
//   height: 40px;
//   background: url($logoPath) no-repeat;
//   background-position: center center;
//   margin: 0 auto;
//   background-size: contain;
//   @include respond-below(sm) {
//     width: 80px;
//   }
// }
